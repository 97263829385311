
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

.ubuntu-bold {
    font-family: "Ubuntu", sans-serif;
    font-weight: 500;
    
    font-style: normal;
  }

.ubuntu-footer-points {
    font-family: "Ubuntu", sans-serif;
    font-weight: 300;
    
    font-style: normal;
  }
.ubuntu-p {
    font-family: "Ubuntu", sans-serif;
    font-weight: 400;
    
    font-style: normal;
  }